* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: #fafafa;
  display: flex;
  justify-content: center;
  align-content: center;
  width: 100%;
  align-items: center;
  height: 100vh;
}

#root {
  width: 100vw;
  height: 100vh;
}
.global-content {
  width: 100% !important;
  max-width: 1500px !important;
}

header {
  background: #eff6fa;
  border: 0;
  border-radius: 6px;
  padding: 12px !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  margin-top: 1rem;

  position: sticky;
  top: 0;
  z-index: 10;
}

.header-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
}

header button,
header a {
  background-color: blueviolet;
  color: #fff;
  border: 0;
  outline: 0;
  display: table;
  line-height: 1;
  padding: 12px 24px;
  border-radius: 4px;
  transition: all .2s ease;
  text-decoration: none;
  font-weight: bold;
}

header button:hover,
header a:hover {
  background: rgb(104, 20, 182);
  color: #fff;
}

.box {
  border: 1px solid #dfdfdf;
  background-color: #fff;
}

.g-btn {
  width: 100% !important;
  height: 40px !important;
  line-height: 40px !important;
  font-size: 15px !important;
}
.g-btn > div,
.g-btn > div > svg {
  width: 40px !important;
  height: 38px !important;
}


.flex-column {
  display: flex;
  flex-direction: column;
}

.gap-4 {
  gap: 8px;
}

.fornecedor-card {
  width: calc(33% - 18px);
  height: auto;
  overflow: hidden;
  border-radius: 8px;
  border: 0px solid #ccc;
  text-decoration: none;
}
.fornecedor-card .thumb {
  width: 100%;
  height: 250px;
  background-color: #a3b5bf;
  background-position: center;
  background-size: 90% auto;
  background-repeat: no-repeat;
  background-size: cover;
}

.fornecedor-card section {
  padding: 16px;
  background-color: #EFF6FA;
  height: 100%;
}
.fornecedor-card:hover section {
  background-color: #dae9f1;
}

.fornecedor-card section h3 {
  color: #3a3a3a;
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 1rem;
}
.fornecedor-card section strong,
.fornecedor-card section h3 {
  text-align: left;
  display: block;
  line-height: 1.2;
}
.fornecedor-card section strong {
  color: blueviolet;
  font-size: 18px;
}
.active {
  color: red;
}

.grid-items {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  flex-direction: row;
  width: 100%;
  margin-bottom: 1rem; margin-top: 1rem;
}

.product-info {
  width: 100%;
  display: flex;
  gap: 48px;
  align-items: flex-start;
  margin-top: 1rem;
}

.complete,
.complete img,
.col-right,
.price {
  width: 100%;
}
.price {
  text-align: left;
}
.price h1 {
  color: #3a3a3a;
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 1rem;
}
.price strong {
  color: blueviolet;
  font-size: 24px;
}
.price {
  padding: 32px;
  background-color: #EFF6FA;
  border-radius: 8px;
  height: auto;
  position: relative;
}
.price .rate-stars {
  display: flex;
  gap: 2px;
  margin: 0;
  padding: 0;
}
.price .rate-stars li { display: flex; }
.price .rate-stars li img { width: 12px; height: 12px; }

.coletivo-item {
  background-color: #fff;
  padding: 0.75rem;
  border-radius: 8px;
  margin-top: 1rem;
  border: 1px dashed #3ab561;
  box-shadow: 0 0 0 4px rgb(58 181 97 / 15%);
  text-align: left;
  /* display: flex;
  gap: 16px; */
}

.coletivo-item h3 {
  color: #3ab561;
  font-size: 18px;
  margin: 0;
  line-height: 1.2;
  background-color: rgb(58 181 97 / 5%);
  padding: 16px;
  border-radius: 4px;
}

.coletivo-item p {
  margin: 0.75rem 0 0 0;
  font-size: 16px;
  font-weight: 500;
  padding-left: 16px;
}
button.comprar {
  outline: none;
  background: 0 0;
  text-transform: uppercase;
  border: none;
  font-size: 14px;
  height: 56px;
  border-radius: 4px;
  padding: 0 32px;
  cursor: pointer;
  transition: all .3s ease;
  box-sizing: border-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
  font-family: poppins,sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: .9px;
  background: #3ab561;
  color: #fff ;
  width: 100%;
  max-width: 300px;
  margin: 1rem 0 0 0;
  -webkit-appearance: none;
  -webkit-font-smoothing: antialiased;
}

button.comprar:hover {
  background-color: #248142;
}

.thumbs-gallery-bundle-itens {
  display: flex;
  gap: 4px;
  margin-top: 1rem;
  width: 100%;
  flex-wrap: wrap;
}
.thumb-bunlde-item {
 width: 12% !important; height:auto;
}

.input,
.full {
  width: 100%;
}
.input {
  padding: 8px;
  border-radius: 8px;
  border: 2px solid #ccc;
  outline: 0;
}
.input:focus {
  border-color: blue;
}
.form-cad {
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 1rem;
}
.form-cad label {
  font-size: 14px;
  font-weight: bold;
}
.control-two-columns {
  display: flex;
  align-items: center;
  gap: 24px;
  justify-content: space-between;
}
.input-content-checkbox {
  padding: 14px;
  border-radius: 8px;
  border: 2px solid #ccc;
  display: flex;
  justify-content: space-between;
}

button[type="submit"] {
  background-color: blue;
  border: 0;
  color: #dfdfdf;
  padding: 16px;
  border-radius: 8px;
}
button[type="submit"]:hover {
  background-color: rgb(0, 0, 158);
}

.flex-alc-jtspbt {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flex-alc {
  display: flex;
  align-items: center;
}

small {
  line-height: 1;
}

.txt-under {
  text-decoration: underline;
}

.download-list {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 0;
  padding: 8px;
  background-color: #fff;
  border-radius: 6px;
  border: 1px solid rgba(204, 204, 204, 0.5);
}

.download-list li {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(58, 58, 58, 0.05);
  padding: 6px;
  border-radius: 4px;
}
.download-list li:hover {  
  background-color: rgba(58, 58, 58, 0.08);
}
.download-list li div {
  display: flex;
  gap: 10px;
  align-items: center;
}
.download-list li div .thumb {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 32px;
  height: 32px;
  border-radius: 4px;
  display: block;
}
.download-list li div h4 {
  font-size: 16px;
  margin: 0;
}
.download-list li a {
  font-size: 10px;
  font-weight: 500;
  background-color: #3ab561;
  letter-spacing: 0.09rem;
  color: #fff;
  padding: 4px 8px;
  border-radius: 4px;
  text-transform: uppercase;
  text-decoration: none;
  transition: all .3s ease;
}
.download-list li a:hover {  
  background-color: #248142;
}

.btn-editar-produto {
  border: 0;
  background: #0d6efd;
  color: #fff;
  font-size: 14px;
  line-height: 1;
  display: block;
  padding: 6px 12px;
  border-radius: 4px;
  position: absolute;
  top: 8px;
  right: 8px;
  transition: all .2s ease;
  text-decoration: none;
}

.btn-editar-produto:hover {
  background: #0b5ed7;
  color: #fff;
}

.radio-group-download-itens {
  display: flex;
  gap: 4px;
  padding: 14px;
  border-radius: 8px;
  border: 2px solid rgba(204, 204, 204, 0.5);
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
}

.down-list-record {
  padding: 12px;
  border-radius: 8px;
  background-color: rgba(204, 204, 204, 0.0);
  border: 1px solid rgba(204, 204, 204, 0.5);
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.down-list-record .item {
  background-color: rgba(204, 204, 204, 0.15);
  padding: 14px;
  border-radius: 4px;
}

.down-list-record .item .remove {
  background-color: #f44336;
  color: white;
  border: 0;
  outline: 0;
  display: table;
  line-height: 1;
  padding: 12px 24px;
  margin-top: 0.75rem;
  border-radius: 4px;
  transition: all .2s ease;
  text-decoration: none;
}

.down-list-record .item .remove:hover {
  background: #cd2418;
  color: #fff;
}

.fake-input div {
  padding: 8px;
  border-radius: 8px;
  border: 2px solid #ccc;
  height: 44px;
  outline: 0;
  background: #e7e7e7;
}

@media (max-width: 750px) {
  .header-menu { gap: 8px; }
  header button,
  header a { padding: 10px 12px; font-size: 14px; }
  .grid-items { gap: 12px; }
  .fornecedor-card { width: calc(50% - 6px); }
  .fornecedor-card .thumb { height: 120px; }
  /* .grid-items { flex-direction: column; flex-wrap: nowrap; } */

  .fornecedor-card section h3 { font-size: 14px; margin-bottom: 0.5rem; }
  .fornecedor-card section strong { font-size: 16px; }

  .product-info { gap: 12px; flex-direction: column; }
  .price { padding: 16px; margin-bottom: 1rem; }
  .price h1 { font-size: 20px; margin-bottom: 0.5rem; }
  .price strong { font-size: 18px; }
  .price strong:nth-child(2) { font-size: 24px; margin-bottom: 0.5rem; display: block; }
  .price p { margin-bottom: 0.35rem; font-size: 14px; }
  .price button.comprar { max-width: 100%; margin-top: 0.5rem; }
  .price small { text-align: center; }

  .download-list li { flex-direction: column; align-items: flex-start; }
  .download-list li a { width: 100%; text-align: center; line-height: 34px; font-size: 12px; }
  .download-list li div .thumb { width: 50px; height: 50px; }
}